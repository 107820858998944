import React  from 'react'
// import { TransitionState, TransitionPortal, TransitionLink } from "gatsby-plugin-transition-link";
// import TransitionLink from 'gatsby-plugin-transition-link'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import {useSpring, animated} from 'react-spring'
// import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import './menu.sass'
// import LinkCard from './LinkCard'
import { MorphLink } from './LinkCardMorph'


const MenuLink = ({item: link}) => {
  // const link = item;
  // const [hover, setHover] = useState();
  // export const MorphLink = ({to, id, img, bg, title, description, duration, isReverse, arrowDirection, children}) => {

  return (
    <MorphLink
      to={link.path}
      id={link.slug}
      title={link.title}
      description={link.description}
      duration={1.5}
      img={link.image}
      bg={link.background}
      isReverse={false}
    />
  )
}


const MenuList = ({data}) => {
  // console.log(data);

  const { edges: projects } = data.allMarkdownRemark
  const { mount, transitionStatus, entry } = useTransitionState();
  const isExiting = transitionStatus==='exiting'
  const duration =  isExiting ? 500 : 1000;
  // const { morph, setMorph } = useContext( MorphContext );
  const contentFade = useSpring(
    {
      config: { duration: duration },
      // opacity: mount ? 1 : 0,
      from : {opacity:0},
      to : {opacity: (transitionStatus==='entered') ? 1 : 0},
      delay: (transitionStatus==='entered') ? (entry.state.appearAfter || 0) : 0
    }
  );


  const getClassForPosition = (i) => {
    let classes = '';
    switch (i%6) {
      case 0:
        classes = "is-5-desktop is-6-tablet smaller crunched";
        break;
      case 1:
        classes = "is-6-desktop is-7-tablet is-offset-6-desktop is-offset-5-tablet";
        break;
      case 2:
        classes = "is-6-desktop is-8-tablet is-offset-3-desktop is-offset-2-tablet";
        break;
      case 3:
        classes = "is-5-desktop is-6-tablet is-offset-7-desktop is-offset-6-tablet smaller crunched";
        break;
      case 4:
        classes = "is-6-desktop is-7-tablet";
        break;
      case 5:
        classes = "is-12";
    }
    return classes;
  }

  const links = projects.map( ({ node: project }, i) => {
    const link = project.frontmatter;
    link.path = project.fields.slug;
    link.slug = link.path.split('/')[2];
    link.morphID = 'morph-' + link.slug;
    link.image.height = link.imageHeight;

    const showFade = (entry.state.fromSlug !== link.slug) || (transitionStatus==='exiting')
    return (
      <div className="columns menuRow" key={project.id} >
        <animated.div className={"column " + getClassForPosition(i)} style={ showFade ? contentFade : null }>
          <MenuLink item={link}/>
        </animated.div>
      </div>
    );
  });

  return(
    <div id='Menu'>
      {links}
    </div>
  );
}


// export default MenuList
export default () => (
  <StaticQuery
    query={graphql`
      query MenuListQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___featuredPosition] }
          filter: { frontmatter: { templateKey: { eq: "project" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                isFeatured
                featuredPosition
                imageWidth
                imageHeight
                image {
                  childImageSharp {
                    fluid(maxWidth: 700, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                background {
                  childImageSharp {
                    fluid(maxWidth: 700, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <MenuList data={data} count={count} />}
  />
)