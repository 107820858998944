import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
// import Layout from '../components/Layout'
// import Menu from '../components/Menu'
import Menu from '../components/MenuList'
// import BlogRoll from '../components/BlogRoll'


export const IndexPageTemplate = () => (
  <div className='container contentWrap'>
    <Menu/>
  </div>
)

// IndexPageTemplate.propTypes = {
//   title: PropTypes.string,
//   heading: PropTypes.string,
//   links: PropTypes.array,
// }

const IndexPage = React.memo((props) => {
  // const { frontmatter } = data.markdownRemark
  // console.log('Home render: ' + props.transitionStatus);
  // console.log(props);

  return (
    <IndexPageTemplate
      // title={frontmatter.title}
      // links={frontmatter.links}
    />
  )
});


// const IndexPage = () => {
//   // const { frontmatter } = data.markdownRemark
//   console.log('Home render');

//   return (
//     <IndexPageTemplate
//       // title={frontmatter.title}
//       // links={frontmatter.links}
//     />
//   )
// }

// IndexPage.propTypes = {
//   data: PropTypes.shape({
//     markdownRemark: PropTypes.shape({
//       frontmatter: PropTypes.object,
//     }),
//   }),
// }

export default IndexPage

// export const pageQuery = graphql`
//   query IndexPageTemplate {
//     markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
//       frontmatter {
//         title
//       }
//     }
//   }
// `
